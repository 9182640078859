// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import Card from './Card';
import H2 from './H2';
import getImageData from '../helpers/ImageHelper';
import TripCard from './TripCard';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faXmark } from '@fortawesome/pro-regular-svg-icons';
import SmallText from './SmallText';
import Slider from './Slider';
import { SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';

function TripsCardGrid({
  data,
  alignment,
  className,
  showDetails,
  titleLeft,
  subtitleBold,
  setBadges,
  onLoadMore,
  showLoadMore,
  featured,
  mobileAlign,
  noPadding
}) {
  const [slices, setSlices] = useState(6);
  const [visible, setVisible] = useState([]);
  const router = useRouter();

  const sliceSize = () => {
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      return 3;
    }
    return 6;
  };

  const hasLoadMore = useMemo(() => !!onLoadMore, [onLoadMore]);

  useEffect(() => {
    setSlices(sliceSize());
  }, [data]);

  useEffect(() => {
    if ((data?.Cards?.length < 6) && featured) {
      return setVisible(data?.Cards?.slice(0, 3));
    }
    if (hasLoadMore) {
      return setVisible(data?.Cards);
    }
    setVisible(data?.Cards?.slice(0, slices));
  }, [data, slices, hasLoadMore]);

  const showMore = () => {
    setSlices(slices + sliceSize());
    if (onLoadMore) return onLoadMore();
  };

  const items = useMemo(() => {
    if (hasLoadMore) {
      return data?.Cards;
    }
    return visible;
  }, [data, visible, hasLoadMore]);
  return (
    <div
      className={`${className} lg:mt-[96px] ${alignment
        ? 'border-t border-solid border-dark-50 pt-16 lg:mt-24 lg:max-w-[730px]'
        : 'border-none '
        } ${mobileAlign ? 'mt-16' : 'mt-9'} grid justify-items-center text-dark-800`}
    >
      {data?.title && (
        <H2
          className={`${alignment || titleLeft ? 'text-left ' : 'text-center'
            } w-full pb-6 lg:pb-12`}
          text={data?.title}
        />
      )}
      {data?.subtitle && (
        <div
          className={`${titleLeft ? 'text-left' : 'max-w-[52.25rem] text-center'} ${subtitleBold ? 'font-semibold text-xl leading-[26px] md:text-[22px] md:leading-[28.6px]' : 'text-base leading-normal'
            } text-black_lighter font-poppins w-full pt-3 pb-[18px] md:pt-8  md:text-xl`}
        >
          {data?.subtitle}
        </div>
      )}
      {data.badges?.length ? (
        <div className="w-full">
          <div className="hidden w-full flex-wrap gap-4 pt-6 md:pt-0 pb-12 text-left lg:flex">
            {data.badges.map((badge) => (
              <div
                key={badge?.name}
                className="flex items-center gap-x-2 rounded-full bg-tertiary-extraLight px-4 py-2"
              >
                <SmallText text={badge?.name} className="text-dark-800" />
                <FontAwesomeIcon
                  icon={faXmark}
                  className="cursor-pointer text-dark-800"
                  onClick={() => {
                    setBadges(data?.badges.filter((b) => b !== badge));
                  }}
                />
              </div>
            ))}
          </div>
          <div className="w-full">
            <Slider slidesPerView="auto" border={false} marginTop={noPadding ? true : false} spaceBetween={16} className="lg:hidden pb-[26px] md-pb-0">
              {data.badges.map((badge) => (
                <SwiperSlide key={badge?.name}>
                  <div className="flex items-center gap-x-2 rounded-full bg-tertiary-extraLight px-4 py-2">
                    <SmallText text={badge?.name} className="text-dark-800" />
                    <FontAwesomeIcon icon={faXmark} className="text-dark-800" />
                  </div>
                </SwiperSlide>
              ))}
            </Slider>
          </div>
        </div>
      ) : null}
      <div className="card-grid grid grid-cols-1 place-items-center gap-y-8 gap-x-[49.5px] md:grid-cols-2 lg:grid-cols-3">
        {items?.map((trip, index) =>
          showDetails ? (
            <TripCard
              key={`trip-card-details-${trip?.attributes?.slug}-${index}`}
              trip={trip}
              container_full
            />
          ) : (
            <Card
              key={`trip-card-${trip?.attributes?.slug}-${index}`}
              card={trip}
              image={getImageData(trip?.image).src}
              alt={getImageData(trip?.image).alt}
              width={getImageData(trip?.image).width}
              height={getImageData(trip?.image).height}
              container_full
            />
          ),
        )}
      </div>
      <div className="block pt-12 pb-14">
        {(data?.Cards?.length > 6 && data.Cards.length > visible.length) ||
          (showLoadMore && onLoadMore) ? (
          <Button onClick={showMore} type="secondary" className="text-center text-base lg:w-auto">
            <FontAwesomeIcon icon={faChevronDown} className="mr-2" /> Load more
          </Button>
        ) : (
          ''
        )}
        {data?.button ? (
          <Button
            onClick={data?.button?.url ? () => router.push(data?.button?.url) : () => { }}
            type={data?.button?.type === 'primary' ? 'main' : 'outline'}
            className="text-center text-base lg:w-auto"
          >
            {data?.button?.text}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default TripsCardGrid;
