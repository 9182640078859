import SubHeadline from './SubHeadline';
import H2 from './H2';
import getImageData from '../helpers/ImageHelper';
import H3 from './H3';
import H4 from './H4';
import NMImage from './shared/NMImage';
import Link from 'next/link';

export default function IconGrid({ ...data }) {
  return (
    <div className="full-width-section">
      <div className={`${data.background === 'accent' ? ' bg-secondary-extraLight' : ''}`}>
        {data.icon_grid_type === 'primary' ? (
          <div className="content-wrapper mx-auto w-full items-center py-16 px-6 text-dark-800 lg:max-w-[838px] lg:text-center xl:pb-[73px]">
            <H2 text={data.title} className="text-center" />
            <div className="font-sourceSanPro mx-auto grid-cols-3 space-y-10 pb-[44px] pt-9 lg:grid lg:flex-row lg:justify-center lg:gap-12 lg:space-y-0 lg:py-[48px] ">
              {data?.items?.map((item) => (
                <div
                  key={item.id}
                  className="mx-auto flex flex-row items-center gap-x-5 lg:block lg:space-y-4 lg:text-lg"
                >
                  <div className="rounded-full md:flex md:h-[108px] md:w-[108px] md:items-center md:justify-center md:bg-[#D4EEED] lg:mx-auto">
                    <div className="h-[47px] w-[41px] md:h-[64px] md:w-[54.52px]">
                      <NMImage
                        className="xl:mx-auto xl:h-24 xl:w-24"
                        src={getImageData(item.icon).src}
                        alt={getImageData(item.icon).alt}
                        layout="responsive"
                        width={getImageData(item.icon).width}
                        height={getImageData(item.icon).height}
                      />
                    </div>
                  </div>
                  <div className="relative block">
                    {item.title && <H4 className="pb-3 lg:pb-4 text-dark-800" text={item.title} />}
                    <SubHeadline className="text-dark-800" text={item.text} />
                  </div>
                </div>
              ))}
            </div>
            {data.button ? (
              <Link href={data?.button?.url || ''}>
              <div className="flex justify-center">
                <button
                  type="button"
                  className=" text-medium rounded-full bg-secondary-light py-[14px] px-[24px] font-poppins text-base font-medium text-dark-800"
                >
                  {data.button?.text}
                </button>
              </div>
              </Link>
            ) : null}
          </div>
        ) : (
          <div className="mx-auto w-full items-center px-6 lg:text-center">
            <div className="mx-auto w-full md:max-w-[830px] lg:max-w-[830px] xl:max-w-[836px] text-dark-800">
              <H2 className="pb-6 text-center text-dark-800 xl:pb-6" text={data.title} />
              <SubHeadline text={data.subtitle} className="text-dark-800" />
              <div className="font relative lg:mt-[85px] mt-6 mb-16 flex flex-row justify-center gap-x-[48px] md:mb-20">
                <div className="relative grid-cols-3 justify-center gap-x-12 md:grid">
                  {data?.items?.map((item) => (
                    <div
                      key={item.id}
                      className="relative mb-4 flex px-4 md:mb-0 md:block md:w-[246px] md:px-0"
                      style={{ backgroundColor: item.color }}
                    >
                      <div className="flex items-start pt-4 md:absolute md:-top-11 md:w-full md:items-center md:justify-center md:p-0">
                        <div className="h-[30px] w-[27px] md:h-[78px] md:w-[69px]">
                          <NMImage
                            src={getImageData(item.icon).src}
                            alt={getImageData(item.icon).alt}
                            width={getImageData(item.icon).width}
                            height={getImageData(item.icon).height}
                            layout="responsive"
                          />
                        </div>
                      </div>
                      <div className="relative block">
                        {item.title && <H3 text={item.title} />}
                        <div className="font-sans relative ml-4 py-4 text-base text-black-600 md:mx-6 md:pt-16 md:pb-6">
                          {item.text}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
