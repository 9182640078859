/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faCircleArrowRight } from '@fortawesome/pro-regular-svg-icons';
import H1 from './H1';
import getImageData from '../helpers/ImageHelper';
import H2 from './H2';
import NMImage from './shared/NMImage';
import Paragraph from './Paragraph';

export default function Hero({ ...data }) {
  const lists = data.List?.items;
  const image = getImageData(data?.image);

  switch (data.hero_grid_type) {
    case 'primary':
      return (
        <div className="hero-image-component relative -mx-[24px] h-[600px] w-screen xl:h-[500px]">
          <div className="absolute top-0 z-20 h-full w-full bg-hero" />
          {image.src && (
            <NMImage
              src={image.src}
              alt={image.alt}
              layout="fill"
              objectFit="cover"
              loading="eager"
              priority
            />
          )}
          <div className="absolute inset-0 z-50 m-auto h-full w-full">
            <div className="content-wrapper mx-auto w-full pt-[320px] text-white sm:pt-[180px] md:pt-[260px] lg:pt-[260px] xl:pt-[200px] 2xl:max-w-[1280px] 2xl:pt-[180px]">
              <div className="xl:w-[50%]">
                <H1 className="pb-[16px] xl:text-[72px]" text={data.title} />
                <Paragraph
                  className="pb-[32px] font-normal"
                  text={data.subtitle}
                  fontSizeClass="text-xl "
                />
              </div>
              {data?.button && (
                <a
                  href={data?.button?.url || ''}
                  target={data?.button?.newTab ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                >
                  <button
                    type={data?.button?.type}
                    className=" rounded-full bg-tertiary-main py-[14px] px-[24px] text-center font-poppins text-[16px] font-medium leading-4 text-dark-800"
                  >
                    <span className="flex items-center gap-2">
                      <FontAwesomeIcon icon={faArrowRightLong} className="h-3.5 w-3.5" />
                      {data?.button?.text}
                    </span>
                  </button>
                </a>
              )}
            </div>
          </div>
        </div>
      );
    case 'centered':
      return (
        <div className="hero-image-component relative -mx-[24px] h-[600px] w-screen xl:h-[500px]">
          <>
            <div className="absolute top-0 z-20 h-full w-full bg-hero" />
            <NMImage
              src={image.src}
              alt={image.alt}
              layout="fill"
              objectFit="cover"
              className="relative"
            />

            <div className="content-wrapper absolute top-32 z-50 flex h-full w-full flex-col pt-[220px] pb-[48px] md:inset-0 md:pt-[180px] md:pb-[180px] lg:pt-[180px] lg:pb-[180px] lg:text-center xl:pt-[180px] xl:pb-[180px] 2xl:max-w-[1280px] 2xl:pt-[180px] 2xl:pb-[180px]">
              <div className="text-white sm:mx-auto sm:px-1 lg:px-6">
                <H2
                  className="font-natureMeetStandard items-center text-[40px] leading-10 md:leading-[72px] lg:leading-[72px] xl:text-[72px] "
                  text={data.title}
                />
                <Paragraph
                  className="mt-4 mb-6 font-normal lg:mb-0"
                  fontSizeClass="text-xl "
                  text={data.subtitle}
                />
                <div className="bottom-30 absolute text-center sm:bottom-60 md:relative md:-bottom-8 lg:relative lg:-bottom-6 xl:relative 2xl:relative 2xl:-bottom-8">
                  <a
                    href={data?.button?.url || ''}
                    target={data?.button?.newTab ? '_blank' : '_self'}
                    rel="noopener noreferrer"
                  >
                    <button
                      type="button"
                      className="rounded-full bg-background-light py-[14px] px-[24px] text-center font-poppins text-[16px] font-medium leading-4 text-dark-800"
                    >
                      <span>{data.button.text}</span>
                    </button>
                  </a>
                </div>
              </div>

              <div className="mb-6 mt-6 w-full px-6 sm:mt-0 md:w-full lg:w-full xl:w-11/12 2xl:w-2/3">
                {lists?.map((item) => (
                  <div
                    key={item.text}
                    className="mb-4 inline-table font-sans text-base text-white md:text-[18px]"
                  >
                    <span className="mr-2 sm:mr-2">
                      <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        className=" mr-2 h-3.5 w-3.5 text-secondary-main"
                      />
                      {item.text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        </div>
      );
    default:
      return (
        <div className="hero-image-component relative -mx-[24px] h-[600px] w-screen xl:h-[500px]">
          <div className="absolute top-0 z-20 h-full w-full bg-hero" />
          <NMImage
            src={image.src}
            alt={image.alt}
            layout="fill"
            objectFit="cover"
            className="relative"
          />

          <div className="content-wrapper absolute inset-0 z-50 flex h-full w-full flex-col pt-[220px] pb-[48px] sm:grid sm:grid-cols-2 md:grid md:grid-cols-2 md:pt-[180px] md:pb-[180px] lg:grid lg:grid-cols-2 lg:pt-[180px] lg:pb-[180px] xl:grid xl:grid-cols-2 xl:pt-[180px] xl:pb-[180px] 2xl:grid 2xl:max-w-[1280px] 2xl:grid-cols-2 2xl:pt-[180px] 2xl:pb-[180px]">
            <div className="text-white sm:mx-auto sm:px-1 md:w-[393px]">
              <H1
                className="font-natureMeetStandard items-center text-[40px] leading-10 md:leading-[72px] lg:leading-[72px] xl:text-[72px] "
                text={data.title}
              />
              {data.subtitle && (
                <Paragraph
                  fontSizeClass="text-xl "
                  className="pb-[32px] font-normal"
                  text={data.subtitle}
                />
              )}
              <a
                href={data?.button?.url || ''}
                target={data?.button?.newTab ? '_blank' : '_self'}
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="absolute bottom-12 rounded-full bg-background-light py-[14px] px-[24px] text-center font-poppins text-[16px] font-medium leading-4 text-dark-800 sm:bottom-60 md:relative md:-bottom-8 lg:relative lg:-bottom-6 xl:relative 2xl:relative 2xl:-bottom-8"
                >
                  <span>{data.button.text}</span>
                </button>
              </a>
            </div>

            <div className="mb-6 mt-6 w-full sm:mt-0 md:w-full lg:w-full xl:w-10/12 2xl:w-2/3">
              {lists?.map((item) => (
                <div
                  key={item.text}
                  className="mb-4 inline-table font-sans text-base text-white md:text-[18px]"
                >
                  <span className="mr-2 sm:mr-2">
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      className=" mr-2 h-3.5 w-3.5 text-secondary-main"
                    />
                    {item.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
  }
}
